import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import IndexPage from "./pages/Index_Page";

function App() {
    return (
        <Router>
            <Switch>
                <IndexPage />
            </Switch>
        </Router>
    );
}

export default App;
