import React from "react";

import {
    FaDiscord,
    FaTwitter,
    FaTelegramPlane
} from "react-icons/fa";

function Socials() {
    return (
        <>
            <div className="list-community">
                <a href="#" target="_blank" className="btn" rel="noreferrer"><FaDiscord /> Join our Discord</a>
                <a href="#" target="_blank" className="btn" rel="noreferrer"><FaTwitter /> Join our Twitter</a>
                <a href="#" target="_blank" className="btn" rel="noreferrer"><FaTelegramPlane /> Join our Telegram</a>
            </div>
        </>
    );
}

export default Socials;
