import React, { useState, useRef, useEffect } from "react";

import { ToastProvider, useToasts } from 'react-toast-notifications';
import { useWeb3React } from "@web3-react/core";

import BigNumber from "bignumber.js";

import { my_nfts } from "../../assets/images";
import usdt from '../../assets/images/usdt.svg';

import { getNFTFromAddress, updateMetadaNFT } from '../../api/api';

import setData from "../../abis/updateMetadata.json";

const MyNft = () => {

    const { addToast } = useToasts();

    const {
        library,
        chainId,
        account,
        activate,
        deactivate,
        active
    } = useWeb3React();

    const [currentItems, setCurrentItems] = useState([]);
    const [filterItem, setFilterItem] = useState([]);
    const [filterType, setFilterType] = useState("");

    const handleInputChange = (e) => {
        setFilterType(e.target.value);
    }

    const filterByType = (filteredData) => {
        // Avoid filter for empty string
        if (!filterType) {
            return filteredData;
        }

        const filteredNfts = filteredData.filter(
            (item) => item.type === filterType
        );
        return filteredNfts;
    };

    useEffect(() => {
        var filteredData = filterByType(filterItem);
        setCurrentItems(filteredData);

        getMyNFT();

    }, [filterType, account]);

    const getMyNFT = async () => {
        if (account) {
            const listNFT = await getNFTFromAddress(account);
            if (listNFT.isSuccess) {
                //console.log(listNFT.data);
                setCurrentItems(listNFT.data)
            } else {
                setCurrentItems([]);
            }
        }
    }

    const updateMetadata = async (tokenId) => {
        if (account) {
            var contractAddress;
            if (chainId == 56) {
                contractAddress = "";
            }

            if (chainId == 97) {
                contractAddress = "0xe8125dcd6c0e1b7d619f489cc763199889600084";
            }

            const update = await updateMetadaNFT(tokenId);
            console.log(update)
            if (update.isSuccess) {
                const data = update.dataUpdate;
                try {
                    const contract = new library.eth.Contract(setData, contractAddress);
                    const requestUpdatemetadata = await contract.methods.setData(
                        data.tokenId,
                        data.dataKeys,
                        data.dataValues,
                        data.nonce,
                        data.provider,
                        data.providerSignature,
                        data.rootSignature
                    ).send({
                        from: account,
                        // gas: '100000',
                        // gasPrice: BigNumber(3000000000).multipliedBy(6),
                    });

                    if (requestUpdatemetadata) {
                        addToast('Update success !', {
                            appearance: 'success',
                            autoDismiss: true,
                            autoDismissTimeout: 5000
                        });
                    }

                } catch (error) {
                    //console.log(error)
                    addToast(error.message, {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 5000
                    });
                }


            }
        }
    }

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-between align-items-center fs-profile_title-wrap">
                            <div className="fs-profile_title text-white text-left mt-0">
                                MY NFT : ({currentItems.length})
                            </div>
                            <div className="head-filter d-flex justify-content-between align-items-center mb-2">
                                <div className="filter-select-option">
                                    <select onChange={handleInputChange}>
                                        <option data-display="Sale type">Choose Category</option>
                                        <option value="1">Weapon</option>
                                        <option value="2">Armor</option>
                                    </select>
                                </div>
                                <div className="filter-select-option">
                                    <select>
                                        <option data-display="Sale type">Filter</option>
                                        <option value="1">Uncommon</option>
                                        <option value="2">Common</option>
                                        <option value="3">Rare</option>
                                        <option value="4">Epic</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="mynft-list">
                        {
                            currentItems.length > 0 ?
                                currentItems.map((item, index) => {
                                    const rare_type = item.infoDetail.rare == 1 ? 'common' : (item.infoDetail.rare == 2 ? 'uncommon' : (item.infoDetail.rare == 3 ? 'rare' : (item.infoDetail.rare == 4 ? 'epic' : '')))
                                    return (
                                        <div className={'nft-style-item nft-' + rare_type} key={index}>
                                            <div className="card-thumbnail">
                                                <img src={item.imageUrl} alt="NFT_portfolio" />
                                                <div className={'card-badge ' + rare_type}>{rare_type}</div>
                                            </div>
                                            <div className="card-content">
                                                <span className="product-name d-block">{item.name}</span>
                                                <span>ID: {item.tokenId}</span>
                                                {/* <div className="bid-react-area">
                                                <div className="last-bid"><img src={usdt} alt="" />{item.price}</div>
                                            </div> */}
                                                <div className="nft-item-info">
                                                    <span>Power: <strong>{item.infoDetail.power}</strong></span>
                                                    <span>Critical: <strong>{item.infoDetail.critical}</strong></span>
                                                    <span>Damage: <strong>{item.infoDetail.damage}</strong></span>
                                                    <span>Accuracy: <strong>{item.infoDetail.accuracy}</strong></span>
                                                    <span>Range: <strong>{item.infoDetail.range}</strong></span>
                                                </div>
                                                <button className="btn" onClick={() => updateMetadata(item.tokenId)}>Update</button>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                ""
                                
                        }
                    </div>
                </div>
                {
                    currentItems.length > 0 ?
                    ""
                    :
                    <div className="text-center">
                        <span className="text-primary d-block">Sorry, no result found.</span>
                        <span>Please try another search</span>
                    </div>
                }
            </div>
        </>
    );
}

export default MyNft;
