import React, { useState, useMemo, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import PageTitle from "../../components/Page_Title/Page_Title";

import Loading from "../../components/Loading";
import { nfts_market } from "../../assets/images/index.js";



import { 
    FiFilter, 
    FiRefreshCw,
    FiX 
} from "react-icons/fi";


const DetailNftFromMarket = () => {

    const [placeBidNft, setPlaceBidNft] = useState(false);

    return (
        <>
            <PageTitle
                title="ZOMBIE #12"
                breadcrumb="ZOMBIE #12"
            />
            <section className="pt-90 pb-90 nft-details">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-12 mb-6">
                            <div className="explore-style-one">
                                <div className="thumb">
                                    <img className="img-fluid" src={nfts_market[1]} alt="prodcut" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12 mb-6">
                            <div className="detail-content">
                                <h2 className="title">The Fantasy Art Illustration</h2>
                                <p className="subtitle">There are a few things worth doing before creating your first NFTs. pencil icon in thetop right. creating your NFTs.</p>
                            </div>
                            <div className="info-wrapper">
                                <span className="price">68.2 ETH</span>
                                <span className="owner">Owner: 0x9e05e646a18bb4cab41aa7992959f019d0aac124</span>
                            </div>
                            <Tabs className="tabs-nft-detail">
                                <TabList className="tabs-nft-head">
                                    <Tab>Bids Info</Tab>
                                    <Tab>History</Tab>
                                    <Tab>Details</Tab>
                                </TabList>
                                <TabPanel className="tabs-nft-body">
                                    <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                    industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                                    scrambled it to make a type specimen book.</p>
                                </TabPanel>
                                <TabPanel className="tabs-nft-body">
                                    <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                    industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                                    scrambled it to make a type specimen book.</p>
                                </TabPanel>
                                <TabPanel className="tabs-nft-body">
                                    <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                    industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                                    scrambled it to make a type specimen book.</p>
                                </TabPanel>
                            </Tabs>
                            <button className="btn btn-medium w-100 mt-4" onClick={() => setPlaceBidNft(!placeBidNft) }>
                                <span>Place Bid</span>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            {
                placeBidNft && (
                    <div className={ !placeBidNft ? "placebid-modal-wrapper modal" : "placebid-modal-wrapper modal fade show"}>
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content show">
                                <div className="modal-header flex-column px-8">
                                    <h3 className="modal-title" id="placeBitLaebl">Place a Bid</h3>
                                    <FiX className="btn-custom-closer" size={24} onClick={() => { setPlaceBidNft(!placeBidNft) }} />
                                </div>
                                <div className="modal-body px-8 py-5">
                                    <div className="placebid-form-box">
                                        <h5 className="title">Your bid</h5>
                                        <div className="bid-content">
                                            <div className="bid-content-top">
                                                <div className="bid-content-left">
                                                    <input id="value" type="text" name="value" />
                                                    <span>ETH</span>
                                                </div>
                                            </div>

                                            <div className="bid-content-mid">
                                                <div className="bid-content-left">
                                                    <span>Your Balance</span>
                                                    <span>Service fee</span>
                                                    <span>Total bid amount</span>
                                                </div>
                                                <div className="bid-content-right">
                                                    <span>9578 ETH</span>
                                                    <span>10 ETH</span>
                                                    <span>9588 ETH</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bit-continue-button">
                                            <a href="connect.html" className="btn btn-primary w-100">Place a bid</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                )
            }
        </>
    );
}

export default DetailNftFromMarket;
