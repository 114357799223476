import React, { useState } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import ReactImageVideoLightbox from "react-image-video-lightbox";
import { Swiper, SwiperSlide } from 'swiper/react';
import YouTube from 'react-youtube';

import 'animate.css';
import Fancybox from "./fancybox.js";


import { FiPlayCircle, FiLinkedin, FiAlignJustify, FiX, FiBook } from "react-icons/fi";
import { FaTwitter, FaDiscord, FaTelegramPlane, FaYoutube } from "react-icons/fa";


// import Fancybox from "./fancybox.js";

import '../assets/css/style.css';
import '../assets/css/responsive.css';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/thumbs/thumbs.min.css';
// import 'swiper/css';
// import "swiper/css/pagination";
// import "swiper/css/thumbs";
// import "swiper/css/navigation";

//import video from '../assets/images/demo.mp4';
import google_play from '../assets/images/app/Google.svg';
import line from '../assets/images/backgrounds/line.svg';
import line_title from '../assets/images/backgrounds/line_title.png';
import line_title_2 from '../assets/images/backgrounds/line_title_2.png';
import border from '../assets/images/backgrounds/border.png';
import border_2 from '../assets/images/backgrounds/border_2.png';
import border_3 from '../assets/images/backgrounds/border_3.png';
import banner from '../assets/images/banner.png';
import banner_main from '../assets/images/feature/dead_cover_nologo.png';
import craft_to_earn from '../assets/images/feature/craft-to-earn.png';

import { gallery } from "../assets/images/index.js";
import { tech_partners } from "../assets/images/index.js";
import { feature_img } from "../assets/images/index.js";
import { pve } from "../assets/images/index.js";
import { coop } from "../assets/images/index.js";
import { pvp } from "../assets/images/index.js";
import { socials } from "../assets/images/index.js";
// import Video from '../assets/images/video.mp4';


import SwiperCore, { Pagination, Navigation, Thumbs } from 'swiper';
SwiperCore.use([Pagination, Navigation, Thumbs]);


function PageGame() {

	const opts = {
		height: '390',
		width: '640',
		playerVars: {
			autoplay: 1,
			rel: 0,
			showinfo: 0,
			loop: 1
		},
	};

	const [openVideo, setOpenVideo] = useState(false);

	const [lightboxOpen, setlightboxOpen] = useState(false);
	const [thumbsSwiper, setThumbsSwiper] = useState(null);

	return (
		<>
			<main>
				<section className="slider-area slider-bg" style={{ backgroundImage: `url(${banner_main})` }}>
					<div className="slider-content text-center">
						<h2 className="tlt">
							Strike Back:
							<span>Dead Cover</span>
						</h2>
						<p>
						Have you ever dreamed of becoming a super agent with extraordinary combat abilities? And what if you could earn money while playing the game? Become Agent 47 in this thrilling adventure shooting game, using the Unity and Metamask platform to connect with blockchain and allow players to earn money from the game. Perform daring actions, infiltrate terrorist bases, eliminate targets, and collect rare items to upgrade your weapons. Use your skills to confront challenging obstacles and become a master thief capable of defeating the most skilled enemies. What are you waiting for? Join Agent 47 today and become one of the most successful money-making gamers.
						</p>
						<div className="btns">
							<a href="https://play.google.com/store/apps/details?id=com.brayang.strikeback2&hl=vi&gl=US" target="_blank"><img className="slider-content-img" src={google_play} alt="" /></a>
						</div>
					</div>
					<div className="line-bottom" style={{ backgroundImage: `url(${line})` }}></div>
				</section>
				<section id="about-us" className="gamestory-wrap third-about-bg pt-120 pb-90">
					<div className="container custom-container">
						<div className="row align-items-center">
							<div className="col-lg-6 order-0 order-lg-2">
								<div className="third-about-img text-right position-relative">
									<div className="video" style={{ backgroundImage: `url(${border})` }}>
										<div data-type="youtube" data-id="wbMdWpRseg4" data-width="800" data-height="300" className="lightbox">
											<LazyLoadImage
												loading="lazy"
												alt="Dead Cover"
												src={banner}
												effect="blur"
											/>
											<span className="play" onClick={() => setlightboxOpen(!lightboxOpen)}>
												<FiPlayCircle size={48} />
											</span>
										</div>
									</div>
								</div>
								<div className="text-center">
									<a className="btn-document" href="https://docs.deadcover.com/" target="_blank">Document</a>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="third-about-content">
									<div className="third-title-style">
										<h2>About Game</h2>
										<div className="line_title" style={{ backgroundImage: `url(${line_title})` }}></div>
										<div className="inner">
											<p>
												High-tech terrorism has caused attacks on some major cities, but they have all been eliminated by the government's defense forces. After multiple failures, this terrorist group has devised a new plan to attack the central city with advanced weapons of the future, which are smarter and more destructive.

												The government has discovered a new base of operations for the group, where private mercenaries are present and strict security measures protect their production of these new weapons. The new weapons are still being perfected.

												Taking advantage of the situation, the military has sent multiple forces to attack the group's weapon production facility. A fierce battle ensued, and the government forces were no longer at an advantage. The enemy even used their new weapons to attack back, causing heavy casualties.

												After being attacked, the terrorist group strengthened their mercenary protection and accelerated their weapon production, ready to use their new weapons if attacked again. Therefore, the government needs Agent 47.

												As a "super warrior" with the support of technology, you will receive each mission, infiltrate, quickly eliminate targets, and retreat. The missions will need to be carried out from the outside and gradually penetrate deeper into their base. You will encounter more sophisticated forces and even more dangerous weapons. Be careful.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section id="about-us" className="gamestory-wrap third-about-bg pb-90">
					<div className="container custom-container">
						<div className="row align-items-center">
							<div className="col-lg-7">
								<div className="third-about-img text-right position-relative">
									<div className="video" style={{ backgroundImage: `url(${border})` }}>
										<div className="lightbox">
											<LazyLoadImage
												loading="lazy"
												alt="Dead Cover"
												src={craft_to_earn}
												effect="blur"
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-5">
								<div className="third-about-content">
									<div className="third-title-style">
										<h2>Craft To Earn</h2>
										<div className="line_title" style={{ backgroundImage: `url(${line_title})` }}></div>
										<div className="inner">
											<p>
											Our Craft to Earn feature allows players to combine multiple identical NFT pieces to create a complete NFT item, such as guns and armor, within the game. To obtain these crafting pieces, players need to open loot boxes to receive them randomly. Additionally, we offer an airdrop referral program where players can receive NFT key tokens to successfully combine the NFT pieces.

Crafting to Earn is an engaging aspect of the game, encouraging players to assemble different NFT pieces to create valuable NFT items. By opening loot boxes and combining the NFT pieces, players experience an exciting and suspenseful journey, eagerly anticipating the NFT item they will acquire. The combination and exploration of different NFT pieces also foster interaction and exchange within the player community.

Craft to Earn provides players with the opportunity to build a unique and valuable NFT collection. It also creates a fair playing field where everyone can participate and enjoy the distinctive experiences of the NFT world.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section id="nft" className="third-about-bg nft-wrap pt-90 pb-90">
					<div className="container custom-container">
						<div className="row mt-30 align-items-end">
							<div className="col-lg-5 col-12">
								<div className="third-title-style text-left mb-5">
									<h2>FEATURE</h2>
									<div className="line_title" style={{ backgroundImage: `url(${line_title})` }}></div>
									<p>Guns, Ammunition, and Items in the game will be designed on the BSC platform with top-notch graphics to create the most real feeling for players when participating in the game.</p>
								</div>
								<Swiper
									autoplay={{
										delay: 2500,
										disableOnInteraction: false,
									}}
									slidesPerView={2}
									spaceBetween={30}
									loop={true}
									onSwiper={setThumbsSwiper}
									modules={[Thumbs]}
									className="guns_slider_thumb"
									breakpoints={{
										"@0.00": {
											"slidesPerView": 2,
											"spaceBetween": 10
										},
										"@0.75": {
											"slidesPerView": 2,
											"spaceBetween": 20
										},
										"@1.00": {
											"slidesPerView": 2,
											"spaceBetween": 40
										},
										"@1.50": {
											"slidesPerView": 2,
											"spaceBetween": 30
										}
									}}
								>
									{
										feature_img.map((item, index) => {
											return (
												<SwiperSlide key={index}>
													<div className="latest-games-items">
														<div className="latest-games-thumb">
															<LazyLoadImage
																loading="lazy"
																alt="Dead Cover"
																src={item}
																effect="blur"
															/>
														</div>
													</div>
												</SwiperSlide>
											)
										})
									}
								</Swiper>
							</div>
							<div className="col-lg-7 col-12">
								<Swiper
									thumbs={{ swiper: thumbsSwiper }}
									className="guns-slider"
									slidesPerView={1}
									spaceBetween={30}
									loop={true}
									modules={[Navigation, Thumbs]}
									navigation={true}
									style={{
										"--swiper-navigation-color": "#fff",
										"--swiper-pagination-color": "#fff",
									}}
									breakpoints={{
										"@0.00": {
											"slidesPerView": 1,
											"spaceBetween": 10
										},
										"@0.75": {
											"slidesPerView": 1,
											"spaceBetween": 20
										},
										"@1.00": {
											"slidesPerView": 1,
											"spaceBetween": 40
										},
										"@1.50": {
											"slidesPerView": 1,
											"spaceBetween": 30
										}
									}}
								>
									{
										feature_img.map((item, index) => {
											return (
												<SwiperSlide key={index}>
													<div className="latest-games-items">
														<div className="latest-games-thumb">
															<LazyLoadImage
																loading="lazy"
																alt="Dead Cover"
																src={item}
																effect="blur"
															/>
														</div>
													</div>
												</SwiperSlide>
											)
										})
									}
								</Swiper>
							</div>
						</div>
					</div>
				</section>
				<section id="nft" className="third-about-bg nft-wrap pt-90 pb-90">
					<div className="container custom-container">
						<div className="row align-items-center">
							<div className="col-lg-8 offset-lg-2 col-12 pb-40">
								<div className="third-title-style text-center">
									<h2>DEMO GAME</h2>
									<div className="line_title_2 m-auto" style={{ backgroundImage: `url(${line_title_2})` }}></div>
								</div>
							</div>
							<div className="col-12">
								{
									!openVideo ?
										<div className="video-full">
											<img src={banner_main} alt="" />
											<span className="play" onClick={() => setOpenVideo(true)}>
												<FaYoutube size={48} />
											</span>
										</div>
										:
										<div className="video-full">
											<div className="embed-responsive embed-responsive-16by9">
												<YouTube videoId="gnEOMedaK8I" opts={opts} />
											</div>
										</div>
								}
							</div>
						</div>
					</div>
				</section>

				<section
					id="game-play"
					className="features-wrap third-about-bg pt-90 pb-90"
				>
					<div className="container custom-container">
						<div className="row align-items-center">
							<div className="col-lg-6 order-0 order-lg-2">
								<div className="third-about-img text-right position-relative">

								</div>
							</div>
							<div className="col-lg-8 offset-lg-2 col-12 pb-40">
								<div className="third-title-style text-center">
									<h2>Game <span>play</span></h2>
									<div className="line_title_2 m-auto" style={{ backgroundImage: `url(${line_title_2})` }}></div>
									<p>Various game modes and non-stop battles, build your character and enjoy victorious moments</p>
								</div>
							</div>
							<div className="col-12">
								<div className="features_list">
									<div className="features-games-items">
										<div className="features-games-left">
											<div className="features-games-thumb">
												<LazyLoadImage
													loading="lazy"
													alt="Dead Cover"
													src={pve}
													effect="blur"
												/>
											</div>
										</div>
										<div className="features-games-right">
											<h4>Large-scale PVE Campaign</h4>
											<p>Players will fight against advanced technology terrorists, trying to maintain and survive in challenging missions or participate in the entire series of missions to achieve success. </p>
										</div>
									</div>
									<div className="features-games-items">
										<div className="features-games-left">
											<div className="features-games-thumb">
												<LazyLoadImage
													loading="lazy"
													alt="Dead Cover"
													src={coop}
													effect="blur"
												/>
											</div>
										</div>
										<div className="features-games-right">
											<h4>Co-op - Multiplayer</h4>
											<p>Team up with friends to participate in cooperative battles, confrontations, and try to survive through increasingly difficult waves of terrorist attacks.</p>
										</div>
									</div>
									<div className="features-games-items">
										<div className="features-games-left">
											<div className="features-games-thumb">
												<LazyLoadImage
													loading="lazy"
													alt="Dead Cover"
													src={pvp}
													effect="blur"
												/>
											</div>
										</div>
										<div className="features-games-right">
											<h4>PVP ( Update Soon )</h4>
											<p>Combat against other players, bet and win to claim glory and prizes.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section id="gallery" className="third-about-bg pt-90 pb-90">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-12 pb-40">
								<div className="third-title-style text-center">
									<h2>Gallery</h2>
									<div className="line_title_2 m-auto" style={{ backgroundImage: `url(${line_title_2})` }}></div>
								</div>
							</div>
							<div className="col-12">
								<div className="gallery">
									<Fancybox>
										{
											gallery.map((item, index) => {
												return (
													<a className="gallery-item gallery-item-hover" key={index} data-fancybox="gallery" href={item}>
														{/* <img src={item} alt="" /> */}
														<LazyLoadImage
															loading="lazy"
															alt=""
															src={item}
															effect="blur"
														/>
													</a>
												)
											})
										}
									</Fancybox>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section
					id="roadmap"
					className="third-about-bg pt-90 pb-90"
				>
					<div className="container custom-container">
						<div className="row align-items-center">
							<div className="col-12 pb-40">
								<div className="third-title-style text-center">
									<h2>Road <span>map</span></h2>
									<div className="line_title_2 m-auto" style={{ backgroundImage: `url(${line_title_2})` }}></div>
								</div>
							</div>
							<div className="col-12">
								<Swiper
									pagination={{
										clickable: true
									}}
									spaceBetween={20}
									slidesPerView={3}
									watchSlidesProgress={true}
									modules={[Pagination]}
									className="roadmap_slider_thumb"
									breakpoints={{
										"@0.00": {
											"slidesPerView": 1,
											"spaceBetween": 10
										},
										"@0.75": {
											"slidesPerView": 2,
											"spaceBetween": 20
										},
										"@1.00": {
											"slidesPerView": 3,
											"spaceBetween": 40
										},
										"@1.50": {
											"slidesPerView": 3,
											"spaceBetween": 30
										}
									}}

								>
									<SwiperSlide>
										<div className="roadmap-item">
											<div className="roadmap-head">
												<div className="tag">Phase 1</div>
												<ul>
													<li className="info">Open DeadCover website</li>
													<li className="info">Create connect web3 in game-app</li>
													<li className="info">Looking for many partners for the project</li>													
												</ul>
											</div>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="roadmap-item">
											<div className="roadmap-head">
												<div className="tag">Phase 2</div>
												<ul>
													<li className="info">Launch NFTs and Token in Game</li>
													<li className="info">Release PvP Mode in game for fun (non-blockchain)</li>
													<li className="info">Open air-drop and new events</li>
													<li className="info">Open crafting features</li>
													<li className="info">Affiliate marketing program</li>
												</ul>
											</div>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="roadmap-item">
											<div className="roadmap-head">
												<div className="tag">Phase 3</div>
												<ul>
													<li className="info">Release PvP Mode in game for blockchain</li>
													<li className="info">Create Tournaments PvP</li>													
													<li className="info">Add more skin and new item NFT</li>
												</ul>
											</div>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="roadmap-item">
											<div className="roadmap-head">
												<div className="tag">Phase 4</div>
												<ul>												
													<li className="info">Update to VR-Gaming</li>												
													<li className="info">10K members</li>
													<li className="info">List token on CEX</li>																						
												</ul>
											</div>
										</div>
									</SwiperSlide>									
								</Swiper>
							</div>
						</div>
					</div>
				</section>


				{/* <section
					id="teams"
					className="third-about-bg team-wrapper pt-90 pb-90"
					style={{ backgroundImage: `url(${backGrounds[10]})` }}
				>
					<div className="container custom-container">
						<div className="row align-items-center">
							<div className="col-12 col-lg-8 offset-lg-2">
								<div className="third-title-style text-center">
									<h2><span>Teams</span></h2>
									<p>Development team always plays an important role in the success of a project. We’ve been working as a team for almost 5 years, and everyone has extensive experience in their respective fields.</p>
								</div>
							</div>
							<div className="col-12">
								<Swiper
									autoplay={{
										delay: 2000,
										disableOnInteraction: false,
									}}
									slidesPerView={5}
									spaceBetween={20}
									pagination={{
										clickable: true
									}}
									modules={[Pagination]}
									className="team-slide"
									breakpoints={{
										"@0.00": {
											"slidesPerView": 1,
											"spaceBetween": 10
										},
										"@0.5": {
											"slidesPerView": 1,
											"spaceBetween": 20
										},
										"@0.75": {
											"slidesPerView": 2,
											"spaceBetween": 20
										},
										"@1.00": {
											"slidesPerView": 3,
											"spaceBetween": 40
										},
										"@1.50": {
											"slidesPerView": 5,
											"spaceBetween": 30
										}
									}}
								>
									{
										teams_core.map((item, index) => {
											return (
												<SwiperSlide key={index}>
													<div className="item-team"
													
													>
														<div className="context">
															<div className="avatar">
																<img src={item.img} alt="" />
															</div>
															<a href={item.linkedin} rel="noreferrer" className="linkedin" target='_blank'>
																<FiLinkedin />
															</a>
															<div className="item-team-text">
																<h4 className="name">{item.name}</h4>
																<span className="position">{item.office}</span>
															</div>
														</div>
													</div>
												</SwiperSlide>
											)
										})
									}
								</Swiper>
							</div>
						</div>
					</div>
				</section> */}
				<section
					className="third-about-bg team-wrapper pt-90 pb-90"
				>
					<div className="container custom-container">
						<div className="row align-items-center">
							<div className="col-12">
								<div className="third-title-style text-center">
									<h2>OUR TECH <span>PARTNER</span></h2>
									<div className="line_title_2 m-auto" style={{ backgroundImage: `url(${line_title_2})` }}></div>
								</div>
							</div>
							<div className="col-12 pb-40 mt-5">
								<div className="developers">
									{
										tech_partners.map((item, index) => {
											return (
												<div className="developer-item" key={index}>
													<img src={item} alt="" />
												</div>
											)
										})
									}
								</div>
							</div>
							{/* <div className="col-12">
								<div className="third-title-style text-center">
									<h2>DEVELOPER</h2>
								</div>
							</div>
							<div className="col-12 mb-3">
								<div className="developers">
									{
										developer.map((item, index) => {
											return (
												<div className="developer-item" key={index}>
													<img src={item} alt="" />
												</div>
											)
										})
									}
								</div>
							</div> */}
						</div>
					</div>
				</section>
				<section
					className="third-about-bg team-wrapper pb-90"
				>
					<div className="container custom-container">
						<div className="row align-items-center justify-content-center">
							<div className="col-12">
								<div className="third-title-style text-center">
									<h2>FOLLOW US <span>ON</span></h2>
									<div className="line_title_2 m-auto" style={{ backgroundImage: `url(${line_title_2})` }}></div>
								</div>
							</div>
							<div className="col-8 pb-40 mt-5">
								<div className="developers">
									{
										socials.map((item, index) => {
											return (
												<a href={item.link} className="social-item" key={index}>
													<img src={item.img} alt="" />
												</a>
											)
										})
									}
								</div>
							</div>
							{/* <div className="col-12">
								<div className="third-title-style text-center">
									<h2>DEVELOPER</h2>
								</div>
							</div>
							<div className="col-12 mb-3">
								<div className="developers">
									{
										developer.map((item, index) => {
											return (
												<div className="developer-item" key={index}>
													<img src={item} alt="" />
												</div>
											)
										})
									}
								</div>
							</div> */}
						</div>
					</div>
				</section>
			</main>

			{lightboxOpen && (
				<div className="video-lightbox">
					<ReactImageVideoLightbox
						data={[
							{
								url: 'https://www.youtube.com/embed/wbMdWpRseg4',
								type: "video",
								title: "Dead Cover | Gameplay Demo",
							}
						]}
						startIndex={0}
						showResourceCount={true}
						onCloseCallback={() => setlightboxOpen(!lightboxOpen)}
					/>
				</div>
			)}
		</>
	);
}

export default PageGame;

