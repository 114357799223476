import React, { useState, useRef, useEffect } from "react";

import { useWeb3React } from "@web3-react/core";

import { ToastProvider, useToasts } from 'react-toast-notifications';

import { FiCopy } from "react-icons/fi";

import NotFound from "../../components/NotFound/NotFound";

const MemberProgram = () => {

    const {
        library,
        activate,
        active,
        chainId,
        account
    } = useWeb3React();

    const data = [
        {
            id: 1,
            account:account,
            linked_date: "2015-03-25T12:00:00-06:30"
        },
        {
            id: 2,
            account:account,
            linked_date: "2015-03-25T12:00:00-06:30"
        },
        {
            id: 3,
            account:account,
            linked_date: "2015-03-25T12:00:00-06:30"
        }
    ];

    const [dataMember, setDataMember] = useState(data);

    const ReferralCopyToClipBoard = () => {
        const { addToast } = useToasts();

        const copyToClipBoard = async copyMe => {
            try {
                await navigator.clipboard.writeText(copyMe);
                addToast('Copied Successfully', {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 2000
                })
            } catch (err) {
                addToast('Failed to copy!', {
                    appearance: 'error',
                    autoDismiss: true,
                    autoDismissTimeout: 2000
                })
            }
        };
        return (
            <FiCopy className="copy" onClick={() => copyToClipBoard(account)} />
        )
    };

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="mtz-profile_title text-white text-left mt-0">
                            Referral List
                        </div>
                        <hr />
                    </div>
                    <div className="col-12">
                        <div className="referral-box-info">
                            <div className="table-responsive">
                                <table className="table table-bordered text-white">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Address</th>
                                            <th scope="col">Linked date</th>
                                        </tr>
                                    </thead>
                                    {
                                        dataMember ? 
                                        <tbody>
                                            <tr>
                                                <th scope="row">1</th>
                                                <td>{account}</td>
                                                <td>2015-03-25T12:00:00-06:30</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">2</th>
                                                <td>{account}</td>
                                                <td>2015-03-25T12:00:00-06:30</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">3</th>
                                                <td>{account}</td>
                                                <td>2015-03-25T12:00:00-06:30</td>
                                            </tr>
                                        </tbody>
                                        :
                                        <tbody>
                                            <tr>
                                                <th colSpan={3}>
                                                    <NotFound />
                                                </th>
                                            </tr>
                                        </tbody>
                                    }
                                    
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MemberProgram;
