import React, { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { FiCopy } from "react-icons/fi";

const AffiliateProgram = () => {

    const {
        library,
        activate,
        active,
        chainId,
        account
    } = useWeb3React();

    const link_ref = `https://deadcover.com/?ref=${account}`

    const ReferralCopyToClipBoard = () => {
        const { addToast } = useToasts();

        const copyToClipBoard = async copyMe => {
            try {
                await navigator.clipboard.writeText(copyMe);
                addToast('Copied Successfully', {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 2000
                })
            } catch (err) {
                addToast('Failed to copy!', {
                    appearance: 'error',
                    autoDismiss: true,
                    autoDismissTimeout: 2000
                })
            }
        };
        return (
            <FiCopy className="copy" onClick={() => copyToClipBoard(link_ref)} />
        )
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="mtz-profile_title text-white text-left mt-0">
                        Affiliate Program
                    </div>
                    <hr />
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-lg-6 col-12">
                    <div className="card-aff">
                        <div className="card-header">
                            <div className="title">Earn <i></i>gems by inviting friends</div>
                            <div className="desc">Earn up to <b>5%</b> from each referral</div>
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                <label>Your referral link:</label>
                                <div className="check">
                                    <span>{link_ref}</span>
                                </div>
                                <div className="action action--copy">
                                    <ToastProvider>
                                        <ReferralCopyToClipBoard />
                                    </ToastProvider></div>
                                <span className="error"></span>
                            </div>
                        </div>
                    </div>
                    <div className="stat">
                        <div className="cell">
                            <div className="count" data-tooltip="<i></i>0<br>$ 0" data-tooltip-id="3"><i></i>0</div>
                            <div className="desc">Income from referrals</div>
                        </div>
                        <div className="cell">
                            <div className="count" data-tooltip="Number of referrals of 1st level:<br>0" data-tooltip-id="4">0</div>
                            <div className="desc">Referrals of 1st level</div>
                        </div>
                        <div className="cell">
                            <div className="count" data-tooltip="Number of referrals of 2nd level:<br>0" data-tooltip-id="5">0</div>
                            <div className="desc">Referrals of 2nd level</div>
                        </div>
                    </div>
                    <div className="card-aff">
                        <div className="card-body">
                            <div className="how">How does it work?</div>
                            <div className="step step--one">
                                <div className="icon"></div>
                                <div className="text">
                                    <div className="title">Advertise referral link among friends or over the internet</div>
                                    <div className="desc">Anyone who registers through your unique referral link will become your referral
                                        forever.</div>
                                </div>
                            </div>
                            <div className="step step--two">
                                <div className="icon"></div>
                                <div className="text">
                                    <div className="title">Receive gems for certain actions of referrals</div>
                                    <div className="desc">Get gems every time your referrals make in-game currency purchases or browse sites in
                                        surfing.</div>
                                </div>
                            </div>
                            <div className="step step--three">
                                <div className="icon"></div>
                                <div className="text">
                                    <div className="title">Dispose of your earnings as you wish</div>
                                    <div className="desc">Spend the gems earned through the affiliate program on recruiting mercenaries or
                                        request payouts.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-12">
                    <div className="card-aff">
                        <div className="card-body">
                            <div className="how">Size of rewards</div>
                            <div className="step">
                                <div className="text">
                                    <div className="title"><span>5%</span>of the amount of each deposit of the first-level referral</div>
                                    <div className="desc">If your first-level referral buys in-game currency, you as their invitee will receive
                                        5% of the purchase amount.</div>
                                </div>
                            </div>
                            <div className="step">
                                <div className="text">
                                    <div className="title"><span>2.5%</span>of the amount of each deposit of the second-level referral</div>
                                    <div className="desc">If your second-level referral (referral of your referral) buys in-game currency, you
                                        will receive 2.5% of the purchase amount.</div>
                                </div>
                            </div>
                            <div className="step">
                                <div className="text">
                                    <div className="title"><span data-tooltip="<i></i>100<br>$ 0.0001" data-tooltip-id="6"><i></i>100</span> for
                                        each view in «Website Surfing»</div>
                                    <div className="desc">When your first-level referral watches sites in surfing, you will receive 100 gems for
                                        each such viewing.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-aff detail">
                        <div className="how">Detailed statistics</div>
                        <div className="d-flex mb-2">
                            <div>Total referral income</div><span></span>
                            <div data-tooltip="<i></i>0<br>$ 0" data-tooltip-id="7"><i></i>0</div>
                        </div>
                        <div className="d-flex mb-2">
                            <div>Income from referrals of 1 level</div><span></span>
                            <div data-tooltip="<i></i>0<br>$ 0" data-tooltip-id="8"><i></i>0</div>
                        </div>
                        <div className="d-flex mb-2">
                            <div>Income from referrals of 2 level</div><span></span>
                            <div data-tooltip="<i></i>0<br>$ 0" data-tooltip-id="9"><i></i>0</div>
                        </div>
                        <div className="d-flex mb-2">
                            <div>Income from surfing view</div><span></span>
                            <div data-tooltip="<i></i>0<br>$ 0" data-tooltip-id="10"><i></i>0</div>
                        </div>
                        <div className="d-flex mb-2">
                            <div>Registrations for today</div><span></span>
                            <div>0 referrals</div>
                        </div>
                        <div className="d-flex mb-2">
                            <div>Registrations for yesterday</div><span></span>
                            <div>0 referrals</div>
                        </div>
                        <div className="d-flex mb-2">
                            <div>Registrations in total</div><span></span>
                            <div>0 referrals</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AffiliateProgram;
