import React from 'react';
import ReactDOM from 'react-dom';
//import 'bootstrap/dist/css/bootstrap.css'
import { Web3ReactProvider } from "@web3-react/core";

import App from './App';
import * as serviceWorker from './serviceWorker';

import Web3 from 'web3';
const getLibrary = (provider) => {
   // const library = new ethers.providers.Web3Provider(provider);
    const library = new Web3(provider);
    library.pollingInterval = 8000; // frequency provider is polling
    return library;
};

ReactDOM.render(
    <Web3ReactProvider getLibrary={getLibrary}>
        <App />
    </Web3ReactProvider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
