import React, { useState, useRef, useEffect, useCallback } from "react";

import { useWeb3React } from "@web3-react/core";

import { ToastProvider, useToasts } from 'react-toast-notifications';

import { FiCopy, FiCornerDownRight, FiCornerUpLeft } from "react-icons/fi";

const OpenBox = () => {

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="mtz-profile_title text-white text-left mt-0">
                            Open Box
                        </div>
                        <hr />
                    </div>
                    <div className="col-lg-12 col-12">
                        <div className="text-center">
                            <span className="text-primary d-block">Sorry, no result found.</span>
                            <span>Please try another search</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OpenBox;
