//import Oculus from './oculusNew.png';

//import Thumb from './thum.png';

import Gallery_Img1 from './gallery/gallery_1.png';
import Gallery_Img2 from './gallery/gallery_2.png';
import Gallery_Img3 from './gallery/gallery_3.png';
import Gallery_Img4 from './gallery/gallery_4.png';
import Gallery_Img5 from './gallery/gallery_5.png';
import Gallery_Img6 from './gallery/gallery_6.png';
import Gallery_Img7 from './gallery/gallery_7.png';
import Gallery_Img8 from './gallery/gallery_8.png';
import Gallery_Img9 from './gallery/gallery_9.png';
import Gallery_Img10 from './gallery/gallery_10.jpg';
import Gallery_Img11 from './gallery/gallery_11.jpg';
import Gallery_Img12 from './gallery/gallery_12.jpg';


import TechPartner_1 from './tech_partners/MetaMask.svg';
import TechPartner_2 from './tech_partners/logoeth.png';
import TechPartner_3 from './tech_partners/unity.svg';

import Pve from './game_play/PVE.png';
import Co_op from './game_play/Co-op.png';
import Pvp from './game_play/pvp.png';


// Feature
import feature_1 from './feature/F_1.png';
import feature_2 from './feature/F_2.png';
import feature_3 from './feature/F_3.png';
import feature_4 from './feature/F_4.png';
import feature_5 from './feature/F_5.png';

// Socials
import facebook from './socials/facebook.png';
import discord from './socials/discord.png';
import telegram from './socials/telegram.png';
import twitter from './socials/twitter.png';
import youtube from './socials/youtube.png';

// NFTS

import Special_1 from './guns/Special_1.png';
import Special_2 from './guns/Special_2.png';
import Special_3 from './guns/Special_3.png';
import Assault_1 from './guns/Assault_1.png';
import Assault_2 from './guns/Assault_2.png';
import Assault_3 from './guns/Assault_3.png';
import Assault_4 from './guns/Assault_4.png';
import Assault_6 from './guns/Assault_6.png';
import Assault_7 from './guns/Assault_7.png';
import Assault_8 from './guns/Assault_8.png';
import Shotgun_1 from './guns/Shotgun_1.png';
import Shotgun_2 from './guns/Shotgun_2.png';
import Shotgun_3 from './guns/Shotgun_3.png';
import Shotgun_4 from './guns/Shotgun_4.png';
import Sniper_1 from './guns/Sniper_1.png';
import Sniper_2 from './guns/Sniper_2.png';
import Sniper_3 from './guns/Sniper_3.png';
import Sniper_4 from './guns/Sniper_4.png';
import Sniper_5 from './guns/Sniper_5.png';
import Sniper_6 from './guns/Sniper_6.png';
import Machine_1 from './guns/Machine_1.png';
import Machine_2 from './guns/Machine_2.png';
import Machine_3 from './guns/Machine_3.png';
import Machine_4 from './guns/Machine_4.png';
import Machine_5 from './guns/Machine_5.png';
import Machine_7 from './guns/Machine_7.png';
import SubMachine_1 from './guns/SubMachine_1.png';
import SubMachine_2 from './guns/SubMachine_2.png';
import SubMachine_4 from './guns/SubMachine_4.png';
import SubMachine_5 from './guns/SubMachine_5.png';
import SubMachine_6 from './guns/SubMachine_6.png';
import SubMachine_7 from './guns/SubMachine_7.png';
import Skin_1 from './skin/skin_1.png';
import Skin_2 from './skin/skin_2.png';
import Skin_3 from './skin/skin_3.png';
import Skin_4 from './skin/skin_4.png';

// My NFT demo

import nft_1 from './nft_skin/Assault-1.png';
import nft_2 from './nft_skin/Assault-2.png';
import nft_3 from './nft_skin/Assault-3.png';
import nft_4 from './nft_skin/Assault-4.png';


const socials = [{
		img: telegram,
		link: 'https://t.me/DeadcoverNFT'
	},
	{
		img: twitter,
		link: 'https://twitter.com/DeadcoverNFT'
	},
	{
		img: youtube,
		link: 'https://www.youtube.com/@StrikeBackDeadCover'
	},
];
const gallery = [
	Gallery_Img1,
	Gallery_Img2,
	Gallery_Img3,
	Gallery_Img4,
	Gallery_Img5,
	Gallery_Img6,
	Gallery_Img7,
	Gallery_Img8,
	Gallery_Img9,
	Gallery_Img10,
	Gallery_Img11,
	Gallery_Img12
];

const my_nfts = [
	{
		name: "FUTURE",
		type: "Assault",
		cate: "weapon",
		thumb: nft_1,
		rare: 1,
		rare_type: 'common',
		power: 9,
		critical: 10,
		damage: 2,
		accuracy: 15,
		range: 55,
		price: 10
	},
	{
		name: "BLACK SNAKE",
		type: "Assault",
		cate: "weapon",
		thumb: nft_1,
		rare: 1,
		rare_type: 'common',
		power: 22,
		critical: 11,
		damage: 4,
		accuracy: 17,
		range: 57,
		price: 24
	},
	{
		name: "LEOPARD",
		type: "Assault",
		cate: "weapon",
		thumb: nft_1,
		rare: 1,
		rare_type: 'common',
		power: 30,
		critical: 15,
		damage: 12,
		accuracy: 25,
		range: 60,
		price: 33
	},
	{
		name: "DQ_89",
		type: "Assault",
		cate: "weapon",
		thumb: nft_1,
		rare: 1,
		rare_type: 'common',
		power: 43,
		critical: 16,
		damage: 24,
		accuracy: 25,
		range: 62,
		price: 48
	},
];

const nfts_market = [{
		name: "STEEL ARM",
		type: "Special",
		cate: "weapon",
		thumb: Special_1,
		rare: 4,
		rare_type: 'epic',
		power: 55,
		critical: 20,
		damage: 25,
		accuracy: 20,
		range: 70,
		price: 61
	},
	{
		name: "EAGLE EYE",
		type: "Special",
		cate: "weapon",
		thumb: Special_2,
		rare: 4,
		rare_type: 'epic',
		power: 70,
		critical: 20,
		damage: 35,
		accuracy: 25,
		range: 75,
		price: 78
	},
	{
		name: "THOR",
		type: "Special",
		cate: "weapon",
		thumb: Special_3,
		rare: 4,
		rare_type: 'epic',
		power: 84,
		critical: 20,
		damage: 45,
		accuracy: 30,
		range: 80,
		price: 93
	},
	{
		name: "FUTURE",
		type: "Assault",
		cate: "weapon",
		thumb: Assault_1,
		rare: 1,
		rare_type: 'common',
		power: 9,
		critical: 10,
		damage: 2,
		accuracy: 15,
		range: 55,
		price: 10
	},
	{
		name: "BLACK SNAKE",
		type: "Assault",
		cate: "weapon",
		thumb: Assault_2,
		rare: 1,
		rare_type: 'common',
		power: 22,
		critical: 11,
		damage: 4,
		accuracy: 17,
		range: 57,
		price: 24
	},
	{
		name: "LEOPARD",
		type: "Assault",
		cate: "weapon",
		thumb: Assault_3,
		rare: 1,
		rare_type: 'common',
		power: 30,
		critical: 15,
		damage: 12,
		accuracy: 25,
		range: 60,
		price: 33
	},
	{
		name: "DQ_89",
		type: "Assault",
		cate: "weapon",
		thumb: Assault_4,
		rare: 1,
		rare_type: 'common',
		power: 43,
		critical: 16,
		damage: 24,
		accuracy: 25,
		range: 62,
		price: 48
	},
	{
		name: "GHOST SPEAR",
		type: "Assault",
		cate: "weapon",
		thumb: Assault_6,
		rare: 1,
		rare_type: 'common',
		power: 50,
		critical: 17,
		damage: 34,
		accuracy: 23,
		range: 65,
		price: 56
	},
	{
		name: "TIGER FANG",
		type: "Assault",
		cate: "weapon",
		thumb: Assault_7,
		rare: 1,
		rare_type: 'common',
		power: 26,
		critical: 14,
		damage: 10,
		accuracy: 23,
		range: 70,
		price: 29
	},
	{
		name: "PIKEMON",
		type: "Assault",
		cate: "weapon",
		thumb: Assault_8,
		rare: 1,
		rare_type: 'common',
		power: 25,
		critical: 13,
		damage: 8,
		accuracy: 21,
		range: 67,
		price: 28
	},
	{
		name: "HA_91",
		type: "Shotgun",
		cate: "weapon",
		thumb: Shotgun_1,
		rare: 2,
		rare_type: 'uncommon',
		power: 27,
		critical: 8,
		damage: 10,
		accuracy: 5,
		range: 30,
		price: 30
	},
	{
		name: "DEAD SHOT",
		type: "Shotgun",
		cate: "weapon",
		thumb: Shotgun_2,
		rare: 2,
		rare_type: 'uncommon',
		power: 40,
		critical: 7,
		damage: 15,
		accuracy: 6,
		range: 32,
		price: 44
	},
	{
		name: "SG 09",
		type: "Shotgun",
		cate: "weapon",
		thumb: Shotgun_3,
		rare: 3,
		rare_type: 'rare',
		power: 54,
		critical: 5,
		damage: 30,
		accuracy: 10,
		range: 35,
		price: 60
	},
	{
		name: "FIRE WINTER",
		type: "Shotgun",
		cate: "weapon",
		thumb: Shotgun_4,
		rare: 2,
		rare_type: 'uncommon',
		power: 41,
		critical: 6,
		damage: 20,
		accuracy: 7,
		range: 35,
		price: 46
	},
	{
		name: "WIND RUNNER",
		type: "Sniper",
		cate: "weapon",
		thumb: Sniper_1,
		rare: 1,
		rare_type: 'common',
		power: 25,
		critical: 24,
		damage: 16,
		accuracy: 52,
		range: 98,
		price: 28
	},
	{
		name: "RED ARROW",
		type: "Sniper",
		cate: "weapon",
		thumb: Sniper_2,
		rare: 1,
		rare_type: 'common',
		power: 26,
		critical: 20,
		damage: 18,
		accuracy: 53,
		range: 100,
		price: 29
	},
	{
		name: "RA_2",
		type: "Sniper",
		cate: "weapon",
		thumb: Sniper_3,
		rare: 1,
		rare_type: 'common',
		power: 11,
		critical: 20,
		damage: 12,
		accuracy: 50,
		range: 90,
		price: 12
	},
	{
		name: "ULTRA SP",
		type: "Sniper",
		cate: "weapon",
		thumb: Sniper_4,
		rare: 1,
		rare_type: 'common',
		power: 24,
		critical: 22,
		damage: 14,
		accuracy: 51,
		range: 92,
		price: 27
	},
	{
		name: "SP_85",
		type: "Sniper",
		cate: "weapon",
		thumb: Sniper_5,
		rare: 1,
		rare_type: 'common',
		power: 47,
		critical: 22,
		damage: 20,
		accuracy: 54,
		range: 94,
		price: 52
	},
	{
		name: "SP_90",
		type: "Sniper",
		cate: "weapon",
		thumb: Sniper_6,
		rare: 1,
		rare_type: 'common',
		power: 70,
		critical: 24,
		damage: 22,
		accuracy: 55,
		range: 96,
		price: 78
	},
	{
		name: "AUTOBOT",
		type: "Machine",
		cate: "weapon",
		thumb: Machine_1,
		rare: 3,
		rare_type: 'rare',
		power: 44,
		critical: 30,
		damage: 26,
		accuracy: 15,
		range: 57,
		price: 49
	},
	{
		name: "IRON WIFE",
		type: "Machine",
		cate: "weapon",
		thumb: Machine_2,
		rare: 3,
		rare_type: 'rare',
		power: 53,
		critical: 35,
		damage: 38,
		accuracy: 17,
		range: 62,
		price: 59
	},
	{
		name: "MT_84",
		type: "Machine",
		cate: "weapon",
		thumb: Machine_3,
		rare: 3,
		rare_type: 'rare',
		power: 54,
		critical: 36,
		damage: 40,
		accuracy: 19,
		range: 67,
		price: 50
	},
	{
		name: "SURE DEAD",
		type: "Machine",
		cate: "weapon",
		thumb: Machine_4,
		rare: 3,
		rare_type: 'rare',
		power: 52,
		critical: 34,
		damage: 36,
		accuracy: 15,
		range: 60,
		price: 58
	},
	{
		name: "CK_89",
		type: "Machine",
		cate: "weapon",
		thumb: Machine_5,
		rare: 4,
		rare_type: 'epic',
		power: 71,
		critical: 38,
		damage: 44,
		accuracy: 23,
		range: 65,
		price: 79
	},
	{
		name: "PINK PANTHER",
		type: "Machine",
		cate: "weapon",
		thumb: Machine_7,
		rare: 4,
		rare_type: 'epic',
		power: 70,
		critical: 37,
		damage: 42,
		accuracy: 21,
		range: 67,
		price: 78
	},
	{
		name: "AI PINK",
		type: "SubMachine",
		cate: "weapon",
		thumb: SubMachine_1,
		rare: 3,
		rare_type: 'rare',
		power: 48,
		critical: 33,
		damage: 32,
		accuracy: 21,
		range: 62,
		price: 53
	},
	{
		name: "BABY DUCK",
		type: "SubMachine",
		cate: "weapon",
		thumb: SubMachine_2,
		rare: 2,
		rare_type: 'uncommon',
		power: 42,
		critical: 29,
		damage: 22,
		accuracy: 23,
		range: 70,
		price: 47
	},
	{
		name: "AI ORANGE",
		type: "SubMachine",
		cate: "weapon",
		thumb: SubMachine_4,
		rare: 2,
		rare_type: 'uncommon',
		power: 40,
		critical: 27,
		damage: 18,
		accuracy: 19,
		range: 55,
		price: 44
	},
	{
		name: "SM_85",
		type: "SubMachine",
		cate: "weapon",
		thumb: SubMachine_5,
		rare: 2,
		rare_type: 'uncommon',
		power: 47,
		critical: 32,
		damage: 30,
		accuracy: 19,
		range: 57,
		price: 52
	},
	{
		name: "SM_90",
		type: "SubMachine",
		cate: "weapon",
		thumb: SubMachine_6,
		rare: 2,
		rare_type: 'uncommon',
		power: 39,
		critical: 26,
		damage: 16,
		accuracy: 17,
		range: 60,
		price: 43
	},
	{
		name: "YELLOW STORM",
		type: "SubMachine",
		cate: "weapon",
		thumb: SubMachine_7,
		rare: 2,
		rare_type: 'uncommon',
		power: 41,
		critical: 28,
		damage: 20,
		accuracy: 21,
		range: 65,
		price: 46
	},
	{
		name: "Common Armor",
		cate: "armor",
		rare_type: "common",
		rare: 1,
		power: 15,
		critical: 3,
		damage: 15,
		accuracy: 10,
		range: 20,
		price: 400,
		thumb: Skin_1
	}, {
		name: "Uncommon Armor",
		cate: "armor",
		rare_type: "uncommon",
		rare: 2,
		power: 30,
		critical: 5,
		damage: 20,
		accuracy: 15,
		range: 25,
		price: 600,
		thumb: Skin_2
	}, {
		name: "Rare Armor",
		cate: "armor",
		rare_type: "rare",
		rare: 3,
		power: 40,
		critical: 7,
		damage: 30,
		accuracy: 25,
		range: 40,
		price: 800,
		thumb: Skin_3
	}, {
		name: "Epic Armor",
		cate: "armor",
		rare_type: "epic",
		rare: 4,
		power: 50,
		critical: 10,
		damage: 35,
		accuracy: 30,
		range: 55,
		price: 900,
		thumb: Skin_4
	}
]


//const developer = [Developer_1, Developer_2];
const tech_partners = [TechPartner_1, TechPartner_2, TechPartner_3];
const pve = Pve;
const coop = Co_op;
const pvp = Pvp;

const feature_img = [feature_1, feature_2, feature_3, feature_4, feature_5];

export {
	feature_img
};

export {
	gallery
};

export {
	tech_partners
};

export {
	pve
};
export {
	coop
};
export {
	pvp
};

export {
	socials
};
export {
	nfts_market
};

export {
	my_nfts
};