import React from "react";

import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

import { ToastProvider, useToasts } from 'react-toast-notifications';
import { FaHandsHelping } from "react-icons/fa";

import { FiShoppingBag, FiCreditCard, FiCopy, FiGift, FiUsers } from "react-icons/fi";

import PageTitle from "../../components/Page_Title/Page_Title";

import banner_main from '../../assets/images/feature/dead_cover_nologo.png';

import CraftToEarn from "./CraftToEarn";
import MyNft from "./MyNft";
import OpenBox from "./OpenBox";
import AffiliateProgram from "./AffiliateProgram";
import MemberProgram from "./ReferralList";

const Dashboard = ({activeTab, address}) => {

    const FormWithToasts = () => {
        const { addToast } = useToasts();

    const copyToClipBoard = async copyMe => {
        try {
                await navigator.clipboard.writeText(copyMe);
                addToast('Copied Successfully', {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 2000,
                    placement: 'bottom-left'
                })
            } catch (err) {
                addToast('Failed to copy!', {
                    appearance: 'error',
                    autoDismiss: true,
                    autoDismissTimeout: 2000,
                    placement: 'bottom-left'
                })
            }
        };
        return (
            <FiCopy className="copy" onClick={() => copyToClipBoard("")} />
        )
    };

    return (
        <>
            <PageTitle
                title="Dashboard"
                background={banner_main}
            />
            <Tab.Container defaultActiveKey={activeTab ? activeTab : "wallet"} >
                <section className="dashboard-page pt-60 pb-90">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="dashboard-wrap">
                                    <div className="dashboard-container">
                                        <div className="dashboard-sidebar">
                                            <div className="mtz-profile_nav">
                                                <Nav variant="pills" className="fs-profile_nav">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="myNft"><FiShoppingBag /> <span>My NFT</span></Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="craft-to-earn"><FiCreditCard /> <span>Craft</span></Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="open-box"><FiGift /> <span>Open Box</span></Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="affiliate"><FaHandsHelping /> <span>Affiliate Program</span></Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="referral"><FiUsers /> <span>Referral List</span></Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </div>
                                        </div>
                                        <div className="dashboard-content">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="myNft">
                                                    <MyNft />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="open-box">
                                                    <OpenBox />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="affiliate">
                                                    <AffiliateProgram />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="craft-to-earn">
                                                    <CraftToEarn />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="referral">
                                                    <MemberProgram />
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Tab.Container>
        </>
    );
}

export default Dashboard;
