import React from "react";

import Accordion from "../../components/Accordion/Accordion";
import Socials from "../../components/Socials";

function FaqPage() {
    return (
        <>
            <section className="about-us-page pt-90 pb-90 faq-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="text-center">GAME OVERVIEW</h1>
                            <div className="accordion-wrap">
                                <Accordion
                                    title="WHAT IS Dead Cover VR?"
                                >
                                    <ul className="list">
                                        <li><span>Dead Cover VR is a survival game that comprises extreme shooting actions, post-apocalyptic horror elements, and blockchain technology. Events in-game happen during the pandemic crisis in the future which are designed based on Virtual Reality (VR) technology and played by Oculus Quest 2 equipment promising to bring players the liveliest fighting experience via the VR glasses.</span></li>
                                        <li><span>Players can travel in a large open world with arms, use their skills to survive, collect modern weapons to eliminate zombie legions, and stay alive as long as they can.</span></li>
                                    </ul>
                                </Accordion>
                                <Accordion
                                    title="HOW TO INSTALL Dead Cover VR?"
                                >
                                    <ul className="list">
                                        <li><span>Please check this guide link: </span></li>
                                        <li><a href="#" target="_blank" ><strong>Link in Google Drive</strong></a></li>
                                    </ul>
                                </Accordion>
                                <Accordion
                                    title="WHAT IS Dead Cover VR TOKEN?"
                                >
                                    <ul className="list">
                                        <li><span>Dead Cover VR is a DeFi gaming ecosystem with the exception that it incorporates blockchain technology.</span></li>
                                        <li>
                                            <span className="d-block mb-3">The main token in the Dead Cover VR ecosystem will be $DCV, which will be operates on Binance Smart Chain (BSC) Network. $DCV holders will receive premium benefits in the ecosystem such as governance rights, submitting proposals, voting on initiatives, staking, etc. and earn profits by participating in Dead Cover VR events. $DCV can be used to purchase ingame money - Zcoin used to upgrade weapons and trade items in the Dead Cover VR Store to help your characters stronger, as well as to increase profits while playing.</span>
                                            <h4>How to own $DCV?</h4>
                                            <ul className="pl-4">
                                                <li><span>Buy from Pancakeswap.</span></li>
                                                <li><span>Inviting others to play the game.</span></li>
                                                <li><span>Playing and overcome in-game challenges.</span></li>
                                                <li><span>Staking DCV token.</span></li>
                                            </ul>
                                            <h4>Dead Cover VR token ($DCV):</h4>
                                            <ul className="pl-4">
                                                <li>Token name: <strong>Dead Cover VR token</strong>.</li>
                                                <li>Ticker: <strong>DCV</strong>.</li>
                                                <li>Blockchain: <strong>Binance Smart Chain</strong>.</li>
                                                <li>Total Supply: <strong>500.000.000</strong>.</li>
                                                <li>Initial Supply : <strong>16.500.000</strong>.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </Accordion>
                                <Accordion
                                    title="Dead Cover VR SYSTEM REQUIREMENT?"
                                >
                                    <ul className="list">
                                        <li><span>Recommended device: <strong>Oculus Quest 2.</strong></span></li>
                                        <li>
                                            <span>We will have a support update for other VR devices in the future.</span>
                                            <span>Click here to install and experience the game: <a href="https://Dead Cover-vr.com/">https://Dead Cover-vr.com/</a></span>
                                        </li>
                                    </ul>
                                </Accordion>
                                
                            </div>
                        </div>
                        <div className="col-12 pt-90">
                        <h1 className="text-center">GAMEPLAY</h1>
                            <div className="accordion-wrap">
                                <Accordion
                                    title="WHAT IS THE REWARD FOR PARTICIPATING IN THE GAME?"
                                >
                                    <ul className="list">
                                        <li><span>Ranking leaderboard to claim your position.</span></li>
                                        <li><span>Earn rewards, conquer multiple daily challenges for double rewards.</span></li>
                                        <li><span>Earn $DCV through many upcoming events and create lasting value for the game.</span></li>
                                    </ul>
                                </Accordion>
                                <Accordion
                                    title="WHAT ARE PVE, CO-OP CAMPAIGN (MULTIPLAYER MODE) AND PVP FEATURES?"
                                >
                                    <span className="d-block mb-3">Players join the solo campaigns or survive the battles with friends to receive items and experience:</span>
                                    <ul className="list pl-4">
                                        <li><span>Large-scale PVE campaign: players survive and slay Zombies in short stages or participate in a full campaign to climb up on the leaderboard.</span></li>
                                        <li><span>Co-Op - Multiplayer mode: team up with friends for cooperative action and survive waves of increasingly challenging Zombie attacks.</span></li>
                                        <li><span>PVP: Players can make a team to fight against other teams to get rewards.</span></li>
                                    </ul>
                                </Accordion>
                                <Accordion
                                    title="WHAT ARE Dead Cover VR OFFICIAL CHANNELS?"
                                >
                                    <span className="d-block mb-3">Follow our social channels to stay up to date with the latest game news!</span>
                                    <ul className="list pl-4">
                                        <li><span>Website: <a href="#" rel="noreferrer" target="_blank">https://deadcover.com/</a></span></li>
                                        <li><span>Telegram Channel: <a href="#" rel="noreferrer" target="_blank">https://t.me/Dead Cover_VR</a></span></li>
                                        <li><span>Telegram Group Global: <a href="#" rel="noreferrer" target="_blank">https://t.me/Dead Cover VR</a></span></li>
                                        <li><span>Twitter: <a href="#" rel="noreferrer" target="_blank">https://twitter.com/Dead Cover VR</a></span></li>
                                        <li><span>Youtube: <a href="#" rel="noreferrer" target="_blank">https://www.youtube.com/channel/UC8CvhT5FLgK83wXUEKdfPng/videos</a></span></li>
                                    </ul>
                                </Accordion>
                                <Accordion
                                    title="IF I HAVE A PROBLEM BESIDES FAQ, WHO CAN I CONTACT FOR SUPPORT?"
                                >
                                    <ul className="list">
                                        <li><span>You can join Telegram: https://t.me/Dead Cover_VR and filter /admin</span></li>
                                        <li>
                                            <span>You can inbox one of them.</span>
                                            <span><strong>Note: Admins never inbox you first or ask you for the secret key and password.</strong></span>
                                        </li>
                                    </ul>
                                </Accordion>
                            </div>
                        </div>
                        <div className="col-12 pt-60">
                            <Socials />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FaqPage;
