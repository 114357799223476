import React, { useEffect, useState, useCallback, useMemo } from "react";
import axios from 'axios';

import {
    Link
} from "react-router-dom";

import PageTitle from "../../components/Page_Title/Page_Title";

import Loading from "../../components/Loading";
import { nfts_market } from "../../assets/images/index.js";
import usdt from '../../assets/images/usdt.svg';

import { 
    FiFilter, 
    FiRefreshCw,
    FiX 
} from "react-icons/fi";

const raritys = [
    {
        value: 1,
        label: 'Common'
    },
    {
        value: 2,
        label: 'Uncommon'
    },
    {
        value: 3,
        label: 'Rare'
    },
    {
        value: 4,
        label: 'Epic'
    }
]

const categorys = [
    {
        value: 'armor',
        label: 'Armor'
    },
    {
        value: 'weapon',
        label: 'Weapon'
    }
]

const type = [
    {
        value: 'Special',
        label: 'Special'
    },
    {
        value: 'Assault',
        label: 'Assault'
    },
    {
        value: 'Shotgun',
        label: 'Shotgun'
    },
    {
        value: 'Sniper',
        label: 'Sniper'
    },
    {
        value: 'Machine',
        label: 'Machine'
    },
    {
        value: 'SubMachine',
        label: 'SubMachine'
    }
]

const MarketPage = () => {
    let currentOffset = 0;
    const[showFilters, checkShowFilters] = useState(false);
    const[loading, setLoading] = useState(false);
    const[listNft, setListNft] = useState(nfts_market);
    const [rarity, setRarity] = useState([]);
    const [category, setCategory] = useState([]);
    const [type_check, setType] = useState([]);

    const loadNfts = () => {
        setListNft(nfts_market);
    };

    const handleSearchRarity = (e) => {
        setRarity(e.target.value);
    }

    const handleSearchCategory = (e) => {
        setCategory(e.target.value);
    }

    const handleSearchType = (e) => {
        setType(e.target.value);
    }

    const getAllDataMarket = useCallback(async () => {
        setLoading(true);
        const filteredData = nfts_market
            .filter(function (el) {
                return !rarity.length || rarity.includes(el.rare);
            }).filter(function (el) {
                return !category.length || category.includes(el.cate);
            }).filter(function (el) {
                return !type_check.length || type_check.includes(el.type);
            });
        setListNft(filteredData);

    });

    const handleClearFilter = () => {
        setRarity([]);
        setCategory([]);
        setType([]);
        setListNft(nfts_market);
    }

    useEffect( () => {
        loadNfts();
        getAllDataMarket();
    },[rarity, category, type_check])
    

    return (
        <>
            <PageTitle
                title="STORE NFT"
                desc="Get your GUNS NFT now"
            />
            <section className="pt-90 pb-90 market-page">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-md-6 col-12">
                            <h3 className="title ">Explore Nfts</h3>
                        </div>
                        <div className="col-md-6 col-12 btn-right">
                            <button className={!showFilters ? "discover-filter-button btn" : "close-filter discover-filter-button btn"} onClick={() => {checkShowFilters(!showFilters)}}>Filter { !showFilters ? <FiFilter /> : <FiX />}</button>
                        </div>
                    </div>
                    <div className={!showFilters ? "filter-wrap" : "filter-wrap box-maxHeight"} style={{
                        overflow: 'hidden',
                        maxHeight : "0",
                        transition: "700ms ease-in",
                        animationDelay: "0ms",
                        marginBottom: "30px"
                    }}>
                        <div className="row mb-5"
                            style={{
                                transition: "700ms ease-in",
                                animationDelay: "0ms"
                            }}
                        >
                                <div className="col-12">
                                    <div className="filter-wrapper filter-exp-expand">
                                        <div className="filter-select-option">
                                            <label className="filter-label">CATEGORY</label>
                                            <select onChange={handleSearchCategory}>
                                                {
                                                    categorys.map((item, index) =>
                                                        <option value={item.value} key={index}>{item.label}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                        <div className="filter-select-option">
                                            <label className="filter-label">RARITY</label>
                                            <select onChange={handleSearchRarity}>
                                                {
                                                    raritys.map((item, index) =>
                                                        <option value={item.value} key={index}>{item.label}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                        {
                                            category === 'weapon' ?
                                            <div className="filter-select-option">
                                                <label className="filter-label">TYPE</label>
                                                <select onChange={handleSearchType}>
                                                    {
                                                        type.map((item, index) =>
                                                            <option value={item.value} key={index}>{item.label}</option>
                                                        )
                                                    }
                                                </select>
                                            </div>
                                            :
                                            ""
                                        }
                                    </div>
                                    <button className="reset-filter-button btn" onClick={handleClearFilter}>Reset Filters <FiRefreshCw /> </button>
                                </div>
                            </div> 
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="market-list">
                                {
                                    listNft.map((item, index) => {
                                        return (
                                            <div className={'nft-style-item nft-' + item.rare_type}  key={index}>
                                                <div className="card-thumbnail">
                                                    <Link to="#">
                                                        <img src={item.thumb} alt="NFT_portfolio" />
                                                    </Link>
                                                    <div className={'card-badge ' + item.rare_type}>{item.rare_type}</div>    
                                                </div>
                                                <div className="card-content">
                                                    <Link to="#"><span className="product-name">{item.name}</span></Link>
                                                    <span>{item.type}</span>
                                                    <div className="bid-react-area">
                                                        <div className="last-bid"><img src={usdt} alt="" />{item.price}</div>
                                                    </div>
                                                    <div className="nft-item-info">
                                                        <span>Power: <strong>{item.power}</strong></span>
                                                        <span>Critical: <strong>{item.critical}</strong></span>
                                                        <span>Damage: <strong>{item.damage}</strong></span>
                                                        <span>Accuracy: <strong>{item.accuracy}</strong></span>
                                                        <span>Range: <strong>{item.range}</strong></span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {
                                listNft.length ?
                                ""
                                : 
                                <div className="text-center">
                                    <span className="text-primary d-block">Sorry, no result found.</span>
                                    <span>Please try another search</span>
                                </div>
                            }
                            {/* <div className="loading-wrap">
                                {
                                    !loading ? <Loading /> : ''
                                }
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default MarketPage;
