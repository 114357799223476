import React, { useEffect, useState, useCallback, useMemo } from "react";
import axios from 'axios';

import {
    Link
} from "react-router-dom";

import PageTitle from "../components/Page_Title/Page_Title";
import banner_main from '../assets/images/feature/dead_cover_nologo.png';
import Box_green from '../assets/images/box/Icon_Chest_Green.png';
import { 
    FiFilter, 
    FiRefreshCw,
    FiX 
} from "react-icons/fi";

const AirdropNft = () => {

    return (
        <>
            <PageTitle
                title="MINT NFT"
                desc="Get your GUNS NFT now"
            />
            <section className="pt-50 pb-90 market-page mint-page">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="mint-wrap" style={{ backgroundImage: `url(${banner_main})` }}>
                                <img src={Box_green} alt="" />
                                <span>You need to connect your MetaMask wallet before you can mint NFT.</span>
                                <button className="btn btn-mint">Mint now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AirdropNft;
