import React from "react";

const NotFound = () => {

    return (
        <div className="no-data">
            <div className="text-center">
                <span className="text-primary d-block">Sorry, no result found.</span>
                <span>Please try another search</span>
            </div>
        </div>
    );
}

export default NotFound;
