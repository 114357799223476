import React, { useState, useEffect } from "react";

const CraftToEarn = () => {


    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="mtz-profile_title text-white text-left mt-0">
                            Craft
                        </div>
                        <hr />
                    </div>
                    <div className="col-lg-12 col-12">
                        <span className="text-white text-center pt-90 pb-90 d-block">Comming Soon</span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CraftToEarn;
