import axios from 'axios';
import { host } from './connectApi';

export {
    getNFTFromAddress,
    updateMetadaNFT
}

const urlGetNFTFromAddress = host + 'getNFTFromAddress';
const urlUpdateMetadaNFT = host + 'updateMetadaNFT';

const getNFTFromAddress= async (ownerAddress) => {
    return axios.post(urlGetNFTFromAddress,
        {
            ownerAddress: ownerAddress,
        },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }
    ).then(response => response.data
    ).then(function (data) {
        return data;
    });
}

const updateMetadaNFT= async (tokenId) => {
    return axios.post(urlUpdateMetadaNFT,
        {
            tokenId: tokenId,
        },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }
    ).then(response => response.data
    ).then(function (data) {
        return data;
    });
}

