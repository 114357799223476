import React, { useState, useEffect } from "react";

import { useToasts } from 'react-toast-notifications';

import Ref_img from '../../assets/images/feature/dead_cover_nologo.png';

import { useWeb3React } from "@web3-react/core";


const Affiliates = ({ }) => {

    const { addToast } = useToasts();

    const {
        library,
        activate,
        active,
        chainId,
        account
    } = useWeb3React();

    const queryParams = new URLSearchParams(window.location.search);
    const ref = queryParams.get('ref');

    const [addressSupportor, setAddressSupportor] = useState(ref);

    const addressContractFSReferral_Test = '';  //Testnet
    const addressContractFSReferral_Main = '';

    const [sponsorOfUser, setSponsorOfUser] = useState(null);

    useEffect(() => {
    }, [account, chainId]);


    return (
        <>
            <div className="ref-wrap" style={{ backgroundImage: `url(${Ref_img})` }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-12 text-center">
                            <h1 className="text-white">DeadCover Affiliate Program</h1>
                            <span className="text-white d-block">Welcome to the world's best crypto affiliate program. Invite friends to play on DeadCover and earn up to a 15% commission on their playing fees. When your friends become DeadCover affiliates, you will also earn third-tier commissions.</span>
                            <div className="aff-address">{addressSupportor}</div>
                            <div className="d-block text-center mt-3">
                                <button className="btn btn-aff btn-sapphire-color">
                                    <div className="fs-btn-wrap">
                                        Choose This Address
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Affiliates;
