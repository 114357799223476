import React, { useState, useRef } from "react";
import { FiChevronRight } from "react-icons/fi";

import { useSpring, animated } from "react-spring";

import './Accordion.css';

function Accordion(props) {
    const [open, setOpen] = useState(false);
    const ref = useRef(null);
    //toggle accordion function
    let toggleHandler = (e) => {
        //switch state
        setOpen(!open);
        console.log(ref.current.clientHeight)
    };

    //conditional styling
    const styles = {
        //if open is true, change color of title
        accordionTitle: {
            color: open ? "#f28920" : "#fff"
        }
    };
    //open animation with react spring

    const openAnimation = useSpring({
        from: { opacity: "0", maxHeight: "53px" },
        to: { opacity: "1", maxHeight: open ? `${ref.current.clientHeight+53}px` : "53px" },
        config: { duration: "300" }
    });

    //rotate animation
    const iconAnimation = useSpring({
        from: {
            transform: "rotate(0deg)",
            color: "#ffff"
        },
        to: {
            transform: open ? "rotate(90deg)" : "rotate(0deg)",
            color: open ? "#f28920" : "#fff"
        },
        config: { duration: "120" }
    });

    return (
        <animated.div className="accordion__item" style={openAnimation} onClick={toggleHandler}>
            <div className="accordion__header">
                <h4 style={styles.accordionTitle}>{props.title}</h4>
                <animated.i style={iconAnimation}>
                    <FiChevronRight />
                </animated.i>
            </div>
            <div className="accordion__content" ref={ref}>{props.children}</div>
        </animated.div>
    );
}

export default Accordion;
